import React from 'react'
import './Enquire.css'

function Enquire() {
    return (
        <>
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">

                    <div className="section-header " data-aos="fade-up">
                        <span>Enquire</span>
                        <h2>Enquire</h2>

                    </div>

{/* added comment */}

                    <div className="row">

                        
                    <div className="col-lg-6 d-flex justify-content-center" data-aos="fade-left" data-aos-delay="100">
                            <img src="/img/services/enquiry.jpg" className="img-fluid" alt="" style={{"height":"50%"}}/>
                        </div>


                        <div className="col-lg-6 mt-5 mt-lg-0">

                            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                <div className="row gy-2 gx-md-3">
                                    <div className="col-md-6 form-group">
                                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                    </div>
                                    <div className="form-group col-12">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                    </div>
                                    <div className="form-group col-12">
                                        <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                    </div>
                                    <div className="my-3 col-12">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center col-12"><button type="submit">Send Message</button></div>
                                </div>
                            </form>

                        </div>

                       
                            

                    </div>

                </div>
            </section>
        </>
    )
}

export default Enquire

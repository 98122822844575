import React from 'react'
import './Subproduct.css';
import Enquire from '../Enquire/Enquire';



function Dental() {
    return (
        <>
            <div className="image" data-aos="fade-up" data-aos-delay="100" >

                <img src="/img/page/p/product_05.png" alt='img' className="i" />

            </div>


            <section id="dental" className="dental">
                <div className="container" data-aos="fade-up">
                    <div className="section-header " data-aos="fade-up">
                        <span>Dental Supplies</span>
                        <h2>Dental Supplies</h2>

                    </div>

                    <div className="row a">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src="/img/services/9.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">

                            <p>
                            Introducing Dental Essentials: <br />
                            Patel & Shah Ecommerce Consultancy's exclusive line of dental supplies.
                             Elevate your dental practice with our meticulously curated range of high-quality dental products,
                              tailored to meet the needs of modern healthcare professionals. <br /> <br />

                              At Patel & Shah, we understand the significance of reliable and effective dental supplies in providing optimal patient care.
                               Our Dental Essentials collection encompasses a comprehensive selection of dental equipment, instruments, consumables, 
                               and hygiene products, sourced from trusted manufacturers worldwide.<br /><br />

                               Discover the power of optimal dental care with Dental Essentials from Patel & Shah Ecommerce Consultancy.
                                Unlock your practice's full potential and provide exceptional dental services with our premium product line.
                            </p>

                        </div>
                    </div>

                </div>
            </section>

            <div className="portfolio-area home-dentist">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 propic">
                            <div className="col-lg-2 col-md-2 col-sm-2 pimg"><img src="img/dental/oral-b.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-xs-12 col-sm-2 pimg"><img src="img/dental/bioxtra.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2 pimg"><img src="img/dental/sonycare.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2 pimg"><img src="img/dental/euthymol.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2 pimg"><img src="img/dental/tepe.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2 pimg"><img src="img/dental/sensodyne.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2 pimg"><img src="img/dental/corsodyl.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2 pimg"><img src="img/dental/cb12.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2 pimg"><img src="img/dental/glide.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2 pimg"><img src="img/dental/dentek.jpg" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <Enquire />
        </>
    )
}

export default Dental

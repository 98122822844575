import React from 'react'
import './Service.css'
import { NavLink } from 'react-router-dom'
import Enquire from '../Enquire/Enquire';


function Service() {
    return (
        <>

            <div className="image" data-aos="fade-up" data-aos-delay="100">

                <img src="img/page/p/service_1.png" alt='img' className="i" />

            </div>

            <section id="service" className="service">
                <div className="container" data-aos="fade-up">


                    <div className="section-header " data-aos="fade-up">
                        <span>Service</span>
                        <h2>Service</h2>

                    </div>

                    {/* <!-- ======= services Section ======= --> */}
                    <section id="work" className="portfolio-mf sect-pt4 route">
                        <div className="container">

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="work-box">
                                        {/* <NavLink to="img/services/work-1.png" data-gallery="portfolioGallery" className="portfolio-lightbox"> */}
                                        <NavLink to="img/services/1.png" data-gallery="portfolioGallery" className="portfolio-lightbox">
                                            <div className="work-img">
                                                {/* <img src="img/services/work-1.png" alt="" className="img-fluid" /> */}
                                                <img src="img/services/1.png" alt="" className="img-fluid" />
                                            </div>
                                        </NavLink>
                                        <div className="work-content">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h1 className="w-title">Wholesaling Merchandise</h1>
                                                </div>
                                                <NavLink to="/Wholesaling_Merchandise">View More</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <hr/> */}
                                <div className="col-md-6">
                                    <div className="work-box">
                                        {/* <NavLink to="img/services/work-1.png" data-gallery="portfolioGallery" className="portfolio-lightbox"> */}
                                        <NavLink to="img/services/2.png" data-gallery="portfolioGallery" className="portfolio-lightbox">
                                            <div className="work-img">
                                                {/* <img src="img/services/work-1.png" alt="" className="img-fluid" /> */}
                                                <img src="img/services/2.png" alt="" className="img-fluid" />
                                            </div>
                                        </NavLink>
                                        <div className="work-content">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h1 className="w-title">Business Consulting</h1>
                                                </div>
                                                <NavLink to="/Business_Consulting">View More</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="container">

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="work-box">
                                        {/* <NavLink to="img/services/work-1.png" data-gallery="portfolioGallery" className="portfolio-lightbox"> */}
                                        <NavLink to="img/services/3.png" data-gallery="portfolioGallery" className="portfolio-lightbox">
                                            <div className="work-img">
                                                {/* <img src="img/services/work-1.png" alt="" className="img-fluid" /> */}
                                                <img src="img/services/3.png" alt="" className="img-fluid" />
                                            </div>
                                        </NavLink>
                                        <div className="work-content">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h1 className="w-title">Business Process Outsourcing</h1>
                                                </div>
                                                <NavLink to="/Business_Process_Outsourcing">View More</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="work-box">
                                        {/* <NavLink to="img/services/work-1.png" data-gallery="portfolioGallery" className="portfolio-lightbox"> */}
                                        <NavLink to="img/services/4.png" data-gallery="portfolioGallery" className="portfolio-lightbox">
                                            <div className="work-img">
                                                {/* <img src="img/services/work-1.png" alt="" className="img-fluid" /> */}
                                                <img src="img/services/4.png" alt="" className="img-fluid" />
                                            </div>
                                        </NavLink>
                                        <div className="work-content">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h1 className="w-title">Promotion & Marketing</h1>
                                                </div>
                                                <NavLink to="/Promotion_Marketing">View More</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>

                </div>
            </section>
            {/* <!-- End services Section --> */}

            <Enquire />
        </>
    )
}

export default Service

import React from 'react'
import './Subservice.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faTty, faTachographDigital, faPeopleRoof, faPhotoFilm, faCommentsDollar } from '@fortawesome/free-solid-svg-icons'
import Enquire from '../Enquire/Enquire';




function Pmarketing() {
    return (
        <>
            <div className="image" data-aos="fade-up" data-aos-delay="100" >

                <img src="/img/page/p/service_04.png" alt='img' className="i" />

            </div>


            <section id="pmarketing" className="pmarketing">
                <div className="container" data-aos="fade-up">
                    <div className="section-header " data-aos="fade-up">
                        <span>Promotion & Marketing Service</span>
                        <h2>Promotion & Marketing Service</h2>

                    </div>

                    <div className="row a">

                        <div className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-2 content">

                            <p>
                                Maximize Your Promotional Impact with Patel and Shah Ecommerce Consultancy's Promotion
                                & Marketing Service!<br /><br />

                                At Patel and Shah, we offer captivating promotional solutions that deliver exceptional
                                results. Whether you're a small or medium-sized business, a startup, or a large global
                                organization, our aim is to help you achieve the highest possible return on your digital
                                promotional and marketing investments.<br /><br />

                                No matter the scale of your budget, we employ creative strategies that are specifically tailored
                                to improve your business revenue. Our team is dedicated to enhancing your online presence,
                                boosting brand awareness, and increasing sales conversions. With our comprehensive services,
                                we take the burden off your shoulders by managing your online presence, including social media
                                and digital advertising.<br /><br />

                                Partner with Patel and Shah Ecommerce Consultancy and experience the difference our Promotion &
                                Marketing Service can make in driving your business forward.
                            </p>

                        </div>
                        <div className="col-lg-6 order-2 order-lg-1" data-aos="fade-left" data-aos-delay="100">
                            {/* <img src="/img/services/17.png" className="img-fluid" alt="" /> */}
                            <img src="/img/services/4.png" className="img-fluid" alt="" />
                        </div>
                    </div>

                </div>
            </section>

            <section id="service" className="service" style={{ 'padding': '0', 'padding-bottom': '80px' }}>
                <div className="container" data-aos="fade-up">

                    <div className="section-header " data-aos="fade-up" >
                        <span>Services</span>
                        <h2>Services</h2>

                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faAddressCard} /></div>
                                <h4 className="title"><a href="">
                                    Profiling & branding</a></h4>
                                <p className="description">We help to influence, win and retain your ideal customers via brand profiling and positioning strategy. </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faTty} /></div>
                                <h4 className="title"><a href="">
                                    Visual communications</a></h4>
                                <p className="description">Our creative, art-minded team designs good visuals that capably and efficiently convey the brand message to boost your business. </p>
                            </div>
                        </div>


                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faTachographDigital} /></div>
                                <h4 className="title"><a href="">
                                    Digital marketing</a></h4>
                                <p className="description"> We help you find more clients, discover new growth opportunities, and ensure that your brand attains the traction it deserves.

</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faCommentsDollar} /></div>
                                <h4 className="title"><a href="">Content marketing</a></h4>
                                <p className="description">Our content marketing team works above and beyond. Efforts are made to design innovative yet effective tactics to communicate with a target audience and expand online presence. </p>
                            </div>
                        </div>


                    </div>
                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faPeopleRoof} /></div>
                                <h4 className="title"><a href="">

                                    Campaign management [organic/paid]</a></h4>
                                <p className="description"> Our digital marketing team makes effort in raising brand awareness across every online channel. </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faPhotoFilm} /></div>
                                <h4 className="title"><a href="">

                                    Social media marketing [SMM]</a></h4>
                                <p className="description">Social media marketing increases brand awareness, generates leads, boosts conversions, and nurtures relationships with customers. A well-designed and implemented SMM plan is the recipe for success and our team is aware of it. </p>
                            </div>
                        </div>


                    </div>




                </div>
            </section>

            <Enquire />
        </>
    )
}

export default Pmarketing

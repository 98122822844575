import React from 'react'
import './Subservice.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse , faTruckFieldUn, faShip, faDumpsterFire,faIndustry, faTruckFast } from '@fortawesome/free-solid-svg-icons'
import Enquire from '../Enquire/Enquire';



function Wmerchandise() {
    return (
        <>

            <div className="image" data-aos="fade-up" data-aos-delay="100" >

                <img src="/img/page/p/service_01.png" alt='img' className="i" />

            </div>


            <section id="wmerchandise" className="wmerchandise">
                <div className="container" data-aos="fade-up">
                    <div className="section-header " data-aos="fade-up">
                        <span>Wholesaling Merchandise Service</span>
                        <h2>Wholesaling Merchandise Service</h2>

                    </div>

                    <div className="row a">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            {/* <img src="/img/services/14.png" className="img-fluid" alt="" /> */}
                            <img src="/img/services/1.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">

                            <p>
                                At Patel and Shah Ecommerce Consultancy, we offer bulk buying opportunities that lead to excellent savings.
                                Serving as a bridge between manufacturers and clients, we prioritize the standards of manufacturers' products
                                and meet customer demands. Our trading exchange business, combined with a reliable supply chain process,
                                ensures compliance with market requirements. <br /> <br />

                                Our dedicated team handles the daily demand for a wide range of products, including pharmaceuticals, FMCG,
                                electronics, e-commerce items, manufacturing goods, healthcare products, OTG supplies, consumer goods, pet food,
                                retail items, and HPC products. Additionally, we provide third-party manufacturing and customized product merchandising
                                at competitive rates, catering to big factories, retailers, dealers, and those seeking bulk materials.<br /><br />

                                With our exceptional warehouse inventory management, we efficiently track and manage stock, ensuring prompt availability.
                                Our shippers are committed to delivering orders as quickly as possible, prioritizing security throughout the transportation process.<br /><br />

                                Feel free to reach out to us for all your bulk buying needs and enjoy the benefits of our reliable services!
                            </p>

                        </div>
                    </div>

                </div>
            </section>

            <section id="service" className="service" style={{ 'padding': '0', 'padding-bottom': '80px' }}>
                <div className="container" data-aos="fade-up">

                    <div className="section-header " data-aos="fade-up" >
                        <span>Services</span>
                        <h2>Services</h2>

                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faHouse} /></div>
                                <h4 className="title"><a href="">Warehousing</a></h4>
                                <p className="description">There is a huge demand for pharmaceutical, electronics, FMCG, pet food, household items, and multiple products sold on e-commerce websites. From the purchase of items to shipping them to customers, our staff handles warehousing aspects.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faTruckFieldUn} /></div>
                                <h4 className="title"><a href="">Supply Chain Management</a></h4>
                                <p className="description">We act as a bridge between manufacturers and consumers. Our traders exchange business with a supply chain process that complies with market requirements. </p>
                            </div>
                        </div>


                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faShip} /></div>
                                <h4 className="title"><a href="">  Import & Export Services</a></h4>
                                <p className="description">Import and export of goods involve a lot than just loading the packages on ships and trucks. Our well-experienced staff handles the entire logistic process carefully.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faIndustry} /></div>
                                <h4 className="title"><a href="">Third-Party Manufacturing</a></h4>
                                <p className="description">If your requirements are specifically related to specialized or tailored products we are also into retailing and 3rd party manufacturing with competitive rates.</p>
                            </div>
                        </div>


                    </div>
                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faDumpsterFire} /></div>
                                <h4 className="title"><a href="">
                                    Cross Border E-commerce</a></h4>
                                <p className="description">Our robust global network base with a myriad of suppliers and customers along with our totally committed sales staff is a great solution to sell local products across the border. You can gain recognition in global markets as well as bring cross-border products to your doorsteps. </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faTruckFast} /></div>
                                <h4 className="title"><a href="">
                                    Transport management [National & Mnternational]</a></h4>
                                <p className="description">To be regarded as the best logistic provider warehouse space and revenue plays a huge role. Our customers can peacefully persist in handling their core business as we committedly handle the national and international logistics. </p>
                            </div>
                        </div>


                    </div>




                </div>
            </section>

            <Enquire />
        </>
    )
}

export default Wmerchandise

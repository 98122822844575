import React from 'react'
import './Subservice.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildingCircleArrowRight, faCheckToSlot, faCartShopping, faCircleInfo, faUser, faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import Enquire from '../Enquire/Enquire';



function Boutsourcing() {
    return (
        <>
            <div className="image" data-aos="fade-up" data-aos-delay="100" >

                <img src="/img/page/p/service_03.png" alt='img' className="i" />

            </div>


            <section id="boutsourcing" className="boutsourcing">
                <div className="container" data-aos="fade-up">
                    <div className="section-header " data-aos="fade-up">
                        <span>Business Process Outsourcing Service</span>
                        <h2>Business Process Outsourcing Service</h2>

                    </div>

                    <div className="row a">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src="/img/services/3.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">

                            <p>

                                Streamline Operations and Boost Efficiency with Patel and Shah Ecommerce Consultancy's
                                Business Process Outsourcing (BPO) Service!<br /><br />

                                Experience enhanced performance and mitigate risks with our top-notch BPO solutions.
                                Patel and Shah's exceptional IT team operates round the clock, serving global clients
                                from our offices in India and London. We take immense pride in our operational excellence,
                                delivering exceptional tech support and managing pre and post-sales services.<br /><br />

                                Our dedicated BPO team is committed to satisfying customer queries, providing valuable suggestions
                                and recommendations to drive customer acquisition. Clients partnering with us have witnessed
                                outstanding service performance improvements coupled with significant cost savings.<br /><br />

                                Trust Patel and Shah Ecommerce Consultancy to optimize your operations through our reliable BPO
                                service, enabling you to focus on core business functions and achieve your goals efficiently.
                            </p>

                        </div>
                    </div>

                </div>
            </section>

            <section id="service" className="service" style={{ 'padding': '0', 'padding-bottom': '80px' }}>
                <div className="container" data-aos="fade-up">

                    <div className="section-header " data-aos="fade-up" >
                        <span>Services</span>
                        <h2>Services</h2>

                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faBuildingCircleArrowRight} /></div>
                                <h4 className="title"><a href="">Back office admin support</a></h4>
                                <p className="description">Patel & Shah Consultancy offers effective back-office administrative services. This makes a difference in offering a high-quality customer response. </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faCheckToSlot} /></div>
                                <h4 className="title"><a href="">
                                    KPO</a></h4>
                                <p className="description">Our smart virtual assistants work on a strategic level and bring lots of success to your business.

                                </p>
                            </div>
                        </div>


                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faCartShopping} /></div>
                                <h4 className="title"><a href=""> E-commerce support</a></h4>
                                <p className="description">Our ecommerce support services efficiently handle the loopholes like cart abandonment, marketplace integration, a website search, etc. which helps in outracing tough competition and allows you to grow consistently. </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faUniversalAccess} /></div>
                                <h4 className="title"><a href="">
                                    Sales support</a></h4>
                                <p className="description">We deliver sales support as agreed and offer monthly analysis reports. We even offer proposals on potential process improvements. </p>
                            </div>
                        </div>


                    </div>
                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faCircleInfo} /></div>
                                <h4 className="title"><a href="">

                                    Product procurement support</a></h4>
                                <p className="description"> We offer accurate analysis of total business expenditure as well as advice on making changes, negotiate with suppliers, use legitimate industry practices, and even offer supply chain management solutions. </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faUser} /></div>
                                <h4 className="title"><a href="">

                                    Customer service [voice/non-voice]</a></h4>
                                <p className="description">Your company can concentrate on the big picture and allow our voice/nonvoice solutions to offer a cohesive Omni channel experience to your customers. </p>
                            </div>
                        </div>


                    </div>




                </div>
            </section>

            <Enquire />

        </>
    )
}

export default Boutsourcing

import React from 'react'
import './Subproduct.css';
import Enquire from '../Enquire/Enquire';


function Pharmaceutical() {
    return (
        <>
            <div className="image" data-aos="fade-up" data-aos-delay="100" >

                <img src="/img/page/p/product_01.png" alt='img' className="i" />

            </div>


            <section id="pharmaceutical" className="pharmaceutical">
                <div className="container" data-aos="fade-up">
                    <div className="section-header " data-aos="fade-up">
                        <span>Pharmaceutical</span>
                        <h2>Pharmaceutical</h2>

                    </div>

                    <div className="row a">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src="/img/services/5.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">

                            <p>
                                Global Healthcare Ecommerce Solutions: <br/> <br/>
                                
                                Welcome to Patel & Shah Ecommerce Consultancy - Your trusted partner for healthcare 
                                and pharmaceutical businesses. <br />
                                We specialize in seamless global supply and innovative ecommerce strategies to elevate your brand in the competitive healthcare market.
                                 With our expertise, your products will reach hospitals, pharmacies, and healthcare entities worldwide.
                                  From medicines to medical devices, vitamins to supplements, we've got you covered.  <br /> <br />
                                  
                                Let's unlock your healthcare business's full potential together.

                            </p>

                        </div>
                    </div>

                </div>
            </section>

            <div className="portfolio-area home-dentist">
                <div className="container">
                    <div className="row">
                        <div align="center" className="col-md-12 propic">
                            <div className="col-lg-2 col-md-2 col-xs-12 col-sm-2"><img src="/img/pharma/abbot.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/astella.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/astrazenica.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/bayer.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/biogen-idec.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/boringer.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/bristo-mayor-squib.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/elli-lilly.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/epsen.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/esai.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/gillied.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/gsk.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/jenssen-cilag.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/lundeback.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/merk-sharp-dome.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/msd.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/novartis.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/pharma/novo-nordisk.jpg" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Enquire />
        </>
    )
}

export default Pharmaceutical

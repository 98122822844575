import React from 'react'
import './Subproduct.css';
import Enquire from '../Enquire/Enquire';


function Organic() {
    return (
        <>

            <div className="image" data-aos="fade-up" data-aos-delay="100" >

                <img src="/img/page/p/product_02.png" alt='img' className="i" />

            </div>


            <section id="organic" className="rganic">
                <div className="container" data-aos="fade-up">
                    <div className="section-header " data-aos="fade-up">
                        <span>Organic</span>
                        <h2>Organic</h2>

                    </div>

                    <div className="row a">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src="/img/services/6.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">

                            <p>
                                Introducing Organic Perfection: <br /> <br />
                                Patel & Shah Ecommerce Consultancy's exclusive line of organic products. <br />
                                Embrace nature's goodness with our carefully curated selection, sourced from trusted organic suppliers around the globe. <br /> <br />

                                {/* At Patel & Shah, we believe in promoting a healthier and sustainable lifestyle.
                                Our Organic Perfection collection includes a wide range of organic products,
                                from natural skincare and beauty essentials to wholesome organic foods and beverages.
                                With a commitment to purity and eco-friendliness, each product is crafted to deliver
                                exceptional quality while respecting the environment.<br /><br /> */}

                                Discover the benefits of going organic with Patel & Shah's Organic Perfection.
                                Nourish your body, mind, and soul with products that are free from harmful chemicals and pesticides.
                                Join us in embracing a greener and more conscious way of living, and let the essence
                                of nature enhance your well-being.<br /><br />

                                Experience the power of nature with Organic Perfection from Patel & Shah Ecommerce Consultancy. Your journey towards a healthier and sustainable lifestyle starts here.                            </p>

                        </div>
                    </div>

                </div>
            </section>

            <div className="portfolio-area home-dentist">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 propic">
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/organic/pulsin.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/organic/natracare.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/organic/meridian.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/organic/fushi.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/organic/Coconut.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-xs-12 col-sm-2"><img src="/img/organic/biona.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/organic/lucy-bee.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/organic/Bragg.jpg" alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <Enquire />
        </>
    )
}

export default Organic

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeLowVision, faTty, faLightbulb, faTaxi, faShieldHalved, faMasksTheater } from '@fortawesome/free-solid-svg-icons'
import Enquire from '../Enquire/Enquire'
// import {} from '@fortawesome/free-solid-svg-icons'
// import {  } from '@fortawesome/free-solid-svg-icons'
import './About.css'

function About() {
    return (
        <>
            {/* <!-- ======= About Section ======= --> */}
            <div class="image" data-aos="fade-up" data-aos-delay="100">
                <img src="img/page/p/about_1.png" alt='img' className="i" />


            </div>

            <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    {/* <div className="section-title">
                        <h2>About Us</h2>

                    </div> */}

                    <div className="section-header " data-aos="fade-up">
                        <span>About Us</span>
                        <h2>About Us</h2>

                    </div>

                    <div className="row content">
                        <div className="col-lg-12">
                            <p>Welcome to Patel & Shah Ecommerce Consultancy! </p>
                            <p>
                                Patel & Shah Ecommerce Consultancy is a dynamic firm dedicated to empowering businesses in the fast-paced world of ecommerce. With a keen understanding of the ever-changing global market, we specialize in helping companies unlock their online potential and establish a strong presence in the digital landscape.
                            </p>
                            <p>
                                As experienced virtual assistants, we pride ourselves on our ability to seamlessly guide businesses through the process of adapting to the global market. From the very beginning, we provide cost-effective solutions that enable our clients to navigate the complexities of international trade and expand their brand reach on a global scale.

                            </p>
                        </div>

                    </div>

                    </div>
            </section>

            <section id="core_value" className="core_value">
                <div className="container" data-aos="fade-up">

                    <div className="section-header " data-aos="fade-up">
                        <span>Core Values</span>
                        <h2>Core Values</h2>

                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faEyeLowVision} /></div>
                                <h4 className="title"><a href="">Long-Term Vision</a></h4>
                                <p className="description">We possess a long-sighted approach, ensuring that our solutions not only address immediate needs but also align with your long-term business goals.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faTty} /></div>
                                <h4 className="title"><a href="">Transparency</a></h4>
                                <p className="description">Transparency is at the core of our relationships with clients and suppliers. We believe in fostering trust through open and honest communication, providing clear insights into our processes, pricing, and decision-making. </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faLightbulb} /></div>
                                <h4 className="title"><a href="">Innovation</a></h4>
                                <p className="description"> We constantly seek innovative solutions that deliver a significant return on investment. Our forward-thinking approach enables us to develop business strategies. </p>
                            </div>
                        </div>
                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faTaxi} /></div>
                                <h4 className="title"><a href="">Quality Services</a></h4>
                                <p className="description"> We are committed to delivering high-quality services that bring tangible value to your business. We continuously strive for excellence in all aspects of our work.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faShieldHalved} /></div>
                                <h4 className="title"><a href="">Privacy & Security</a></h4>
                                <p className="description">We prioritize the privacy and safety of your data. We implement robust security measures to safeguard against unauthorized access, data alteration, disclosure or destruction.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faMasksTheater} /></div>
                                <h4 className="title"><a href="">Determination & Performance</a></h4>
                                <p className="description">Our team is driven and determined to deliver excellence and outstanding performance in every project we undertake. We are passionate about what we do and go above and beyond to achieve exceptional results.</p>
                            </div>
                        </div>
                    </div>




                </div>
            </section>
            {/* <!-- End About Section --> */}

            <Enquire />
        </>
    )
}

export default About

import React from 'react'
import GLightbox from 'glightbox'
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Home.css'
import Enquire from '../Enquire/Enquire';

function Home() {

    useEffect(() => {
        const portfolioLightbox = GLightbox({
            selector: '.portfolio-lightbox'
        });
    }, []);

    return (
        <>
            {/* <!-- ======= Hero Section ======= --> */}
            <section id="hero" className="d-flex align-items-center">
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row justify-content-center">

                        <div  className="col-lg-6 mt-5">

                            <div className=" text-center">
                                <h1>Patel & Shah Ecommerce Consultancy</h1>
                            </div>
                            <div className="text-center">
                                <NavLink to="/about" className="btn-get-started scrollto">About Us</NavLink>
                            </div>
                        </div>
                       
                    </div>

                </div>
            </section>
            {/* <!-- End Hero --> */}

            <section id="wedo" className="portfolio-mf sect-pt4 route">
                <div className="container" >
                    <div className="row">

                        <div className="col-lg-6">
                            <div class="section-header" data-aos="fade-up">
                                <span>What We DO</span>
                                <h2>What We DO </h2>
                            </div>



                            <h5>
                                Keeping a businesses flourishing is a challenge in this cut-throat competitive environment.
                                We step in to assist you, whenever needed allowing you to focus on core business tasks you do best!
                                Patel and Shah Consultancy is a private limited multi-faceted outsourcing service provider partnering with MNCs and
                                industry giants to transform their business models into a simple handling process.


                            </h5>

                        </div>

                        <div className="col-lg-6">
                            <img src="/img/services/11.png" className="img-fluid" alt="" />

                        </div>
                    </div>
                </div>

                <div className="container" >
                    <div className="row">

                        <div className="col-lg-6">
                            <img src="/img/services/12.png" className="img-fluid" alt="" />

                        </div>
                        <div className="col-lg-6">

                            <div class="section-header" data-aos="fade-up">
                                <span>Why Choose Us</span>
                                <h2>Why Choose Us </h2>
                            </div>


                            <h5>
                                Our multi-faceted outsourcing services allow you to focus on your core business tasks while we handle the complexities of
                                transforming your business models into seamless handling processes. With proven expertise, strategic partnerships,
                                and tailored solutions, we streamline your ecommerce operations and drive tangible results.
                                Choose us to thrive in this cut-throat competitive environment and unlock the full potential of your ecommerce venture.


                            </h5>

                        </div>



                    </div>

                </div>

            </section>

            {/* services */}

            {/* <!-- ======= services Section ======= --> */}
            <section id="work" className="portfolio-mf sect-pt4 route">
                <div className="container" >
                    <div class="section-header" data-aos="fade-up">
                        <span>Service</span>
                        <h2>Service </h2>

                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="work-box">
                                {/* <NavLink to="img/services/work-1.png" data-gallery="portfolioGallery" className="portfolio-lightbox"> */}
                                <NavLink to="img/services/1.png" data-gallery="portfolioGallery" className="portfolio-lightbox">
                                    <div className="work-img">
                                        {/* <img src="img/services/work-1.png" alt="" className="img-fluid" /> */}
                                        <img src="img/services/1.png" alt="" className="img-fluid" />
                                    </div>
                                </NavLink>
                                <div className="work-content">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h1 className="w-title">Wholesaling Merchandise</h1>
                                        </div>
                                        <NavLink to="/Wholesaling_Merchandise">View More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <hr/> */}
                        <div className="col-md-6">
                            <div className="work-box">
                                {/* <NavLink to="img/services/work-1.png" data-gallery="portfolioGallery" className="portfolio-lightbox"> */}
                                <NavLink to="img/services/2.png" data-gallery="portfolioGallery" className="portfolio-lightbox">
                                    <div className="work-img">
                                        {/* <img src="img/services/work-1.png" alt="" className="img-fluid" /> */}
                                        <img src="img/services/2.png" alt="" className="img-fluid" />
                                    </div>
                                </NavLink>
                                <div className="work-content">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h1 className="w-title">Business Consulting</h1>
                                        </div>
                                        <NavLink to="/Business_Consulting">View More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="container">

                    <div className="row">
                        <div className="col-md-6">
                            <div className="work-box">
                                {/* <NavLink to="img/services/work-1.png" data-gallery="portfolioGallery" className="portfolio-lightbox"> */}
                                <NavLink to="img/services/3.png" data-gallery="portfolioGallery" className="portfolio-lightbox">
                                    <div className="work-img">
                                        {/* <img src="img/services/work-1.png" alt="" className="img-fluid" /> */}
                                        <img src="img/services/3.png" alt="" className="img-fluid" />
                                    </div>
                                </NavLink>
                                <div className="work-content">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h1 className="w-title">Business Process Outsourcing</h1>
                                        </div>
                                        <NavLink to="/Business_Process_Outsourcing">View More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="work-box">
                                {/* <NavLink to="img/services/work-1.png" data-gallery="portfolioGallery" className="portfolio-lightbox"> */}
                                <NavLink to="img/services/4.png" data-gallery="portfolioGallery" className="portfolio-lightbox">
                                    <div className="work-img">
                                        {/* <img src="img/services/work-1.png" alt="" className="img-fluid" /> */}
                                        <img src="img/services/4.png" alt="" className="img-fluid" />
                                    </div>
                                </NavLink>
                                <div className="work-content">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h1 className="w-title">Promotion & Marketing</h1>
                                        </div>
                                        <NavLink to="/Promotion_Marketing">View More</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/* <!-- End services Section --> */}

            <Enquire />
        </>
    )
}

export default Home

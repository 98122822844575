import React from 'react'
import './Subproduct.css';
import Enquire from '../Enquire/Enquire';


function Fragrance() {
    return (
        <>
            <div className="image" data-aos="fade-up" data-aos-delay="100" >

                <img src="/img/page/p/product_04.png" alt='img' className="i" />

            </div>


            <section id="fragrance" className="fragrance">
                <div className="container" data-aos="fade-up">
                    <div className="section-header " data-aos="fade-up">
                        <span>Fragrance</span>
                        <h2>Fragrance</h2>

                    </div>

                    <div className="row a">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src="/img/services/8.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">

                            <p>
                            Introducing Enchanting Aromas:<br />
                             Patel & Shah Ecommerce Consultancy's exclusive line of fragrance products. 
                             Immerse yourself in a world of captivating scents crafted to elevate your senses and create unforgettable experiences. <br /> <br />

                             At Patel & Shah, we understand the power of fragrance in leaving a lasting impression. 
                             Our Enchanting Aromas collection offers a diverse range of premium fragrances for both personal and ambient use.
                              From luxurious perfumes and colognes to enchanting home fragrances, we curate products that exude elegance and sophistication.<br /><br />

                              Discover the allure of Enchanting Aromas from Patel & Shah Ecommerce Consultancy.
                               Unlock the power of fragrance and elevate your senses to new heights. 
                               Embrace a world of captivating aromas, and let your presence leave a trail of enchantment wherever you go.


                            </p>

                        </div>
                    </div>

                </div>
            </section>

            <div className="portfolio-area home-dentist">
                <div className="container">
                    <div className="row">
                        <div align="center" className="col-md-12 propic">
                            <div className="col-lg-2 col-md-2 col-xs-12 col-sm-2"><img src="/img/fregrance/adidas.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/bio-oil.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/elizabeth-arden.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/beckham.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/benefit.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/jade-goody.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/kim-kardashian.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/lacoste.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/loreal.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/beyonce.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/dana.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/davidoff.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/baldessarini.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/olay.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/bentley.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/garnier.jpg" alt="" /></div>


                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/betty-boop.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/disney.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/fake-bake.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/cacharel.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/angel-schlesser.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/banana-republic.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/bio-oil.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/matrix.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/swarovski.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/giorgio-armani.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/halle-berry.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/iceberg.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/nikos.jpg" alt="" /></div>
                            <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/fregrance/calvin-klein.jpg" alt="" /></div>
                        </div>
                    </div>
                </div> 
            </div>

            <Enquire />
        </>
    )
}

export default Fragrance

import React from 'react'
import './Subservice.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlassMinus, faHourglassStart, faListCheck, faMedal, faSitemap, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import Enquire from '../Enquire/Enquire';



function Bconsulting() {
    return (
        <>
            <div className="image" data-aos="fade-up" data-aos-delay="100" >

                <img src="/img/page/p/service_02.png" alt='img' className="i" />

            </div>





            <section id="bconsulting" className="bconsulting">
                <div className="container" data-aos="fade-up">

                    <div className="section-header " data-aos="fade-up">
                        <span>Business Consulting Service</span>
                        <h2>Business Consulting Service</h2>

                    </div>

                    <div className="row a">

                        <div className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-2 content">

                            <p>
                                In the ever-changing landscape of technology, markets, and opportunities, making the right investment decisions for future business success can be challenging.
                                That's where our expert business consultants at Patel and Shah come in. With our strategic thinking and tailored solutions, we help you optimize your ROI and
                                overcome various business hurdles.<br /><br />

                                At Patel and Shah Ecommerce Consultancy, we understand the difficulties you face in finding resources, managing disruptions, and gaining a fresh perspective
                                on your business. Our business consulting services are designed to meet your unique needs and budget, allowing you to focus on enhancing your core
                                business without the stress.<br /><br />

                                Partner with us to unlock your business's full potential and achieve long-term success!
                            </p>

                        </div>

                        <div className="col-lg-6 order-2 order-lg-1" data-aos="fade-left" data-aos-delay="100">
                            <img src="/img/services/2.png" className="img-fluid" alt="" />
                        </div>
                    </div>

                </div>
            </section>
            <section id="service" className="service" style={{ 'padding': '0', 'padding-bottom': '80px' }}>
                <div className="container" data-aos="fade-up">

                    <div className="section-header " data-aos="fade-up" >
                        <span>Services</span>
                        <h2>Services</h2>

                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faMagnifyingGlassMinus} /></div>
                                <h4 className="title"><a href="">
                                    Market research</a></h4>
                                <p className="description">You have the vision, ideas, and funds but executing them competitively is essential and we make this ‘HOW’ happen.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faHourglassStart} /></div>
                                <h4 className="title"><a href="">Startup consulting</a></h4>
                                <p className="description">Our auditors comply with the latest auditing and assurance standards, which is mandatory! </p>
                            </div>
                        </div>


                    </div>

                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faListCheck} /></div>
                                <h4 className="title"><a href=""> Management consulting</a></h4>
                                <p className="description">We do things uniquely and through analysis identify opportunities, formulate cost-saving strategies to manage your brand, diversify income streams; implement new systems, and more. </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faPeopleGroup} /></div>
                                <h4 className="title"><a href="">
                                    Marketing consulting</a></h4>
                                <p className="description">Discuss your merchandise and items with our well-experienced consultant. They prepare a perfect design, which works competitively well in the national and international market.</p>
                            </div>
                        </div>


                    </div>
                    <div className="row icon-boxes">
                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faMedal} /></div>
                                <h4 className="title"><a href="">
                                    Brand development</a></h4>
                                <p className="description"> Discuss your merchandise and items with our well-experienced consultant. They prepare a perfect design, which works competitively well in the national and international market.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                            <div className="icon-box">
                                <div className="icon"><FontAwesomeIcon icon={faSitemap} /></div>
                                <h4 className="title"><a href="">
                                    Private label products</a></h4>
                                <p className="description">From consumer goods & services to hardware and software, we strongly implement our theories into developing innovative private label products.

                                </p>
                            </div>
                        </div>


                    </div>




                </div>
            </section>

            <Enquire />
        </>
    )
}

export default Bconsulting

import React from 'react';
import './Footer.css'
import { NavLink } from 'react-router-dom';

function Footer() {
    return (
        <>
            {/* <!-- ======= Footer ======= --> */}
            <footer id="footer">

                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-5 col-md-6 footer-contact">
                                <h4>Patel & Shah e-Commerce Consultancy </h4>
                                <p>
                                    A-315, Second Floor, <br />
                                    Sanket Tower,<br />
                                    Nr. Grid Chowkdi<br />
                                    Anand - 388001 <br /><br />
                                    <strong>Phone:</strong> +91 99981 46020<br /> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+91 92656 04586<br /><br />
                                    <strong>Email:</strong> info@patelnshahecom.com<br />
                                </p>
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Home">Home</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/About">About</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Product">Products</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Carrer">Carrer</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Contact">Contact Us</NavLink></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Wholesaling_Merchandise">Wholesaling Merchandise</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Business_Consulting">Business Consulting</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Business_Process_Outsourcing">Business Process Outsourcing</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Promotion_Marketing">Promotion & Marketing</NavLink></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Our Products</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Pharmaceutical">Pharmaceutical</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Organic">Organic</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Health_and_Beauty">Health and Beauty</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Fragrance">Fragrance</NavLink></li>
                                    <li><i className="bx bx-chevron-right"></i> <NavLink to="/Dental_Supplies">Dental Supplies</NavLink></li>
                                </ul>
                            </div>

                            {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                                <h4>Join Our Newsletter</h4>
                                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                                <form action="" method="post">
                                    <input type="email" name="email" /><input type="submit" value="Subscribe" />
                                </form>
                            </div> */}

                        </div>
                    </div>
                </div>

                <hr/>

                <div className="container footer-bottom">

                    {/* <div className="me-md-auto text-md-start"> */}
                        <div className="copyright">
                            &copy; Copyright <strong><span>Patel & Shah e-Commerce Consultancy</span></strong>. All Rights Reserved
                        </div>
                        <div className="credits">

                            Designed by <NavLink className="design" to="https://www.linkedin.com/in/megh-shah-a19813205" target='_blank'>Megh Shah</NavLink>
                        </div>
                    {/* </div> */}
                    {/* <div className="social-links text-center text-md-right pt-3 pt-md-0">
                        <NavLink to="#" className="twitter"><i className="bx bxl-twitter"></i></NavLink>
                        <NavLink to="#" className="facebook"><i className="bx bxl-facebook"></i></NavLink>
                        <NavLink to="#" className="instagram"><i className="bx bxl-instagram"></i></NavLink>
                        <NavLink to="#" className="google-plus"><i className="bx bxl-skype"></i></NavLink>
                        <NavLink to="#" className="linkedin"><i className="bx bxl-linkedin"></i></NavLink>
                    </div> */}
                </div>
            </footer>
            {/* <!-- End Footer --> */}
        </>
    )
}

export default Footer

import React from 'react'
import './Subproduct.css';
import Enquire from '../Enquire/Enquire';



function Healthbeauty() {
    return (
        <>
            <div className="image" data-aos="fade-up" data-aos-delay="100" >

                <img src="/img/page/p/product_03.png" alt='img' className="i" />

            </div>


            <section id="healthbeauty" className="healthbeauty">
                <div className="container" data-aos="fade-up">
                    <div className="section-header " data-aos="fade-up">
                        <span>Health and Beauty</span>
                        <h2>Health and Beauty</h2>

                    </div>

                    <div className="row a">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src="/img/services/7.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">

                            <p>
                            Introducing Radiant Beauty: <br />
                             Patel & Shah Ecommerce Consultancy's exclusive line of health and beauty products. 
                             We understand the importance of feeling confident and embracing your natural beauty,
                              which is why we have curated a collection of exceptional products to enhance your well-being. <br /> <br />

                              Radiant Beauty offers a wide range of carefully selected health and beauty products that promote self-care and nourishment.
                               From skincare essentials and luxurious haircare products to natural cosmetics and wellness supplements,
                                our collection is designed to help you look and feel your best.<br /><br />

                                At Patel & Shah, we prioritize quality and effectiveness. 
                                Our health and beauty products are sourced from trusted brands and suppliers known for their commitment to using natural, 
                                safe, and ethically sourced ingredients. We believe in the power of nature and harness its benefits to deliver products
                                 that promote long-term beauty and well-being.
                            </p>

                        </div>
                    </div>

                </div>
            </section>

            <div className="portfolio-area home-dentist">
            <div className="container">
                <div className="row">
                    <div align="center" className="col-md-12 propic">
                         <div className="col-lg-2 col-md-2 col-xs-12 col-sm-2"><img src="/img/health/4little1.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/abidec.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/nitty-gritty.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/afterbite.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/allevyn.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/odor-eater.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/alpecin.jpg" alt="" /></div> 
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/pampers-wipes.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/anthisan.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/proactiv.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/simple.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/arm-hammer.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/artelac.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/sterimar.jpg" alt="" /></div> 
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/astral.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/valupak.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/avent.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/wellwoman.jpg" alt="" /></div>
                         
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/bio-oil.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/cetaphil.jpg" alt="" /></div> 
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/dermicex.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/durex.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/eno.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/flexitol.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/got2b.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/huggies.jpg" alt="" /></div> 
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/icaps.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/iglu.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/ky-jelly.jpg" alt="" /></div>
                         <div className="col-lg-2 col-md-2 col-sm-2"><img src="/img/health/milton.jpg" alt="" /></div>
                    </div>
                </div>        
            </div>           
        </div>

        <Enquire />
        </>
    )
}

export default Healthbeauty

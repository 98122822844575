import React from 'react'
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';


const select = (el, all = false) => {
  el = el.trim();

  if (el.startsWith('/')) {
    el = el.substring(1);
  }

  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

const scrollto = (hash) => {
  let header = select('#header');
  let offset = header.offsetHeight;

  let cleanedHash = hash.replace('/', ''); // Remove the leading forward slash
  let element = select(cleanedHash);

  if (element) {
    let elementPos = element.offsetTop;
    window.scrollTo({
      top: elementPos - offset,
      behavior: 'smooth',
    });
  }
};


function Navbar() {

  useEffect(() => {

  
    const handleClick = (e) => {
      const clickedElement = e.target;
  const navbar = select('#navbar');

  if (clickedElement.matches('.mobile-nav-toggle')) {
    navbar.classList.toggle('navbar-mobile');
    clickedElement.classList.toggle('bi-list');
    clickedElement.classList.toggle('bi-x');
  }

  if (clickedElement.matches('.navbar a')) {
    navbar.classList.remove('navbar-mobile');
    let navbarToggle = select('.mobile-nav-toggle');
    navbarToggle.classList.toggle('bi-list');
    navbarToggle.classList.toggle('bi-x');
  }

      if (clickedElement.matches('.navbar .dropdown > .abc')) {
        const navbar = select('#navbar');

        if (navbar.classList.contains('navbar-mobile')) {
          e.preventDefault();
          clickedElement.nextElementSibling.classList.toggle('dropdown-active');
        }
      }

      if (clickedElement.matches('.scrollto')) {
        const targetHash = clickedElement.getAttribute('to');

        if (targetHash && select(targetHash)) {
          e.preventDefault();

          let navbar = select('#navbar');

          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile');
            let navbarToggle = select('.mobile-nav-toggle');
            navbarToggle.classList.toggle('bi-list');
            navbarToggle.classList.toggle('bi-x');
          }

          scrollto(targetHash);
        }
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };



  }, []);

  
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">

          {/* <h1 className="logo"><NavLink to="/">Patel & Shah <br/>e-Commerce Consultancy</NavLink></h1> */}
          <img src="/img/Logo_Patel_Shah.png" alt="logo" className="logo-img"  />

          <nav id="navbar" className="navbar">
            <ul>
              <li><NavLink className="nav-link scrollto " to="/Home">Home</NavLink></li>
              <li><NavLink className="nav-link scrollto" to="/About">About</NavLink></li>
              <li className="dropdown"><NavLink className="abc" to="/Service"><span>Services</span> <i className="bi bi-chevron-down"></i></NavLink>
                <ul>
                  <li><NavLink to="/Wholesaling_Merchandise">Wholesaling Merchandise</NavLink></li>
                  <li><NavLink to="/Business_Consulting">Business Consulting</NavLink></li>
                  <li><NavLink to="/Business_Process_Outsourcing">Business Process Outsourcing</NavLink></li>
                  <li><NavLink to="/Promotion_Marketing">Promotion & Marketing</NavLink></li>
                </ul>
              </li>
              <li className="dropdown"><NavLink className="abc xyz" ><span>Products</span> <i className="bi bi-chevron-down"></i></NavLink>
                <ul>
                  <li><NavLink to="/Pharmaceutical">Pharmaceutical</NavLink></li>
                  <li><NavLink to="/Organic">Organic</NavLink></li>
                  <li><NavLink to="/Health_and_Beauty">Health and Beauty</NavLink></li>
                  <li><NavLink to="/Fragrance">Fragrance</NavLink></li>
                  <li><NavLink to="/Dental_Supplies">Dental Supplies</NavLink></li>
                </ul>
              </li>
              {/* <li><NavLink className="nav-link scrollto" to="/Products">Products</NavLink></li> */}
              <li><NavLink className="nav-link scrollto" to="/Carrers">Carrers</NavLink></li>
              <li><NavLink className="nav-link scrollto" to="/Contact">Contact Us</NavLink></li>

            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          {/* <!-- .navbar --> */}

        </div>
      </header>
      {/* <!-- End Header --> */}
    </>
  )
}

export default Navbar

import React, { useEffect } from 'react'
import './Careers.css'
import Isotope from 'isotope-layout';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Enquire from '../Enquire/Enquire';

const select = (el, all = false) => {
  el = el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

function Careers() {

  useEffect(() => {
    const portfolioContainer = select('.portfolio-container');
    if (portfolioContainer) {
      const portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item'
      });

      const portfolioFilters = select('#portfolio-flters li', true);

      portfolioFilters.forEach(filter => {
        filter.addEventListener('click', function (e) {
          e.preventDefault();
          portfolioFilters.forEach(el => el.classList.remove('filter-active'));
          this.classList.add('filter-active');

          portfolioIsotope.arrange({
            filter: this.getAttribute('data-filter')
          });

          portfolioIsotope.on('arrangeComplete', function () {
            AOS.refresh();
          });
        });
      });
    }
  }, []);


  return (
    <>
      <div class="image" data-aos="fade-up" data-aos-delay="100">
        <img src="img/page/p/careers.png" alt='img' className="i" />


      </div>
      {/* <!-- ======= Portfolio Section ======= --> */}
      <section id="portfolio" class="portfolio">
        <div class="container" data-aos="fade-up">

          <div className="section-header " data-aos="fade-up">
            <span>Careers</span>
            <h2>Careers</h2>

          </div>

          <div class="row" data-aos="fade-up" data-aos-delay="150">
            <div class="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" class="filter-active">ALL</li>
                <li data-filter=".filter-bpo">BPO SERVICE</li>
                <li data-filter=".filter-acc">ACCOUNTING AND COMPLIANCE</li>
                <li data-filter=".filter-mer">WHOLESALE MERCHANDISING</li>
              </ul>
            </div>
          </div>

          <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="300">

            <div class="col-lg-3 col-md-6 portfolio-item filter-bpo">
              <div class="portfolio-wrap">
                <div className="icon-box">
                  <h4 className="title"><a href="">E-commerce Asssistant</a></h4>
                  <p className="description">Anand, Gujarat</p>
                  <p className="description">BPO SERVICE</p>
                </div>


              </div>
            </div>

            <div class="col-lg-3 col-md-6 portfolio-item filter-acc">
              <div class="portfolio-wrap">

                <div className="icon-box">
                  <h4 className="title"><a href="">Data Processing Assistant</a></h4>
                  <p className="description">Anand, Gujarat</p>
                  <p className="description">ACCOUNTING AND COMPLIANCE</p> 
                </div>

              </div>
            </div>

            <div class="col-lg-3 col-md-6 portfolio-item filter-acc">
              <div class="portfolio-wrap">
                <div className="icon-box">
                  <h4 className="title"><a href="">E-Commerce Business management</a></h4>
                  <p className="description">Anand, Gujarat</p>
                  <p className="description">ACCOUNTING AND COMPLIANCE</p> 
                </div>


              </div>
            </div>

            <div class="col-lg-3 col-md-6 portfolio-item filter-mer">
              <div class="portfolio-wrap">

                <div className="icon-box">
                  <h4 className="title"><a href="">Logistics Assistant</a></h4>
                  <p className="description">Anand, Gujarat</p>
                  <p className="description">WHOLESALE MERCHANDISING</p> 

                </div>

              </div>
            </div>



          </div>

        </div>
      </section>
      {/* <!-- End Portfolio Section --> */}

      <Enquire />
    </>
  )
}

export default Careers

import React from 'react'
import './Contact.css'

function Contact() {
  return (
    <>
      {/* <!-- ======= Contact Section ======= --> */}
      <div className="image" data-aos="fade-up" data-aos-delay="100">

        <img src="img/page/p/contact_1.png" alt='img' className="i" />

      </div>

      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">

          <div className="section-header " data-aos="fade-up">
            <span>Contact</span>
            <h2>Contact</h2>

          </div>



          <div className="row mt-5">

            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>A-315, Second Floor,
                    Sanket Tower, <br />
                    Nr. Grid Chowkdi,
                    Anand - 388001</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>info@patelnshahecom.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p> +91 99981 46020</p>
                  <p> +91 92656 04586</p>
                </div>

              </div>

            </div>



            <div className="col-lg-8 mt-5 mt-lg-0">

              <form  method="post" role="form" className="php-email-form">
                <div className="row gy-2 gx-md-3">
                  <div className="col-md-6 form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                  </div>
                  <div className="col-md-6 form-group">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                  </div>
                  <div className="form-group col-12">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                  </div>
                  <div className="form-group col-12">
                    <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                  </div>
                  <div className="my-3 col-12">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">Your message has been sent. Thank you!</div>
                  </div>
                  <div className="text-center col-12"><button type="submit">Send Message</button></div>
                </div>
              </form>

            </div>

          </div>



          <div>
            <iframe class="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14738.52588996277!2d72.9504397!3d22.5554738!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4ddd937ce627%3A0xd76b2621ee175175!2sPatel%20%26%20Shah%20E-Commerce%20Consultancy!5e0!3m2!1sen!2sin!4v1690018355984!5m2!1sen!2sin" width="600" height="450" style={{'border':'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          </div>
        </section>
      {/* <!-- End Contact Section --> */}

    </>
  )
}

export default Contact

import React from 'react';
import './App.css';


import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Service from './components/Service/Service';
import Wmerchandise from './components/Subservice/Wmerchandise';
import Bconsulting from './components/Subservice/Bconsulting';
import Boutsourcing from './components/Subservice/Boutsourcing';
import Pmarketing from './components/Subservice/Pmarketing';
import Pharmaceutical from './components/Subprodct/Pharmaceutical';
import Organic from './components/Subprodct/Organic';
import Healthbeauty from './components/Subprodct/Healthbeauty';
import Fragrance from './components/Subprodct/Fragrance';
import Dental from './components/Subprodct/Dental';
import Careers from './components/Careers/Careers';

import ScrollToTop from './components/Scroll/ScrollToTop';




function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/Home" excact element={<Home />} />
        <Route path="/Contact" excact element={<Contact />} />
        <Route path="/About" excact element={<About />} />
        <Route path="/Service" excact element={<Service />} />
        <Route path="/Carrers" excact element={<Careers />} />
        <Route path="Wholesaling_Merchandise" excact element={<Wmerchandise />} />
        <Route path="/Business_Consulting" excact element={<Bconsulting />} />
        <Route path="/Business_Process_Outsourcing" excact element={<Boutsourcing />} />
        <Route path="/Promotion_Marketing" excact element={<Pmarketing />} />
        <Route path="/Pharmaceutical" excact element={<Pharmaceutical />} />
        <Route path="/Organic" excact element={<Organic />} />
        <Route path="/Health_and_Beauty" excact element={<Healthbeauty />} />
        <Route path="/Fragrance" excact element={<Fragrance />} />
        <Route path="/Dental_Supplies" excact element={<Dental />} />
        <Route path="/" excact element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
